import { PoiBrowserService, CacheManager, AuthManager, } from '@workspaces/services';
import { getInstance as getAuth0ServiceIntance } from '@/auth/index';
import { MAP_TYPES, fetchLayerData } from '@deck.gl/carto/typed';
import { getFetchLayerCredentials } from './fetch-layer/fetch-layer.helper';
import { getConnection } from '@/plan/metadata/metadata.helper';
import ErrorMessage from '@/utils/errorMessage';
import { SEPARATOR } from '@/utils/customPois';
import { isArrayEmpty } from '@/helpers/array.helper';
function addTopCategoryToCustomPOIs(customPOIs, categories) {
    const customPOIsWithTopCategory = [];
    for (const customPOI of customPOIs) {
        if (customPOI.sub_category === null || customPOI.sub_category === '') {
            customPOIsWithTopCategory.push({ ...customPOI });
        }
        else {
            const category = categories.find((category) => category.sub_category === customPOI.sub_category);
            customPOIsWithTopCategory.push({
                ...customPOI,
                top_category: category ? category.top_category : customPOI.sub_category,
            });
        }
    }
    return customPOIsWithTopCategory;
}
export default {
    async getCustomPoisList(meta, environment, filterByExpiration = false, avoidCache = false) {
        const cacheManager = CacheManager.getInstance();
        cacheManager.updateCacheInfo(meta);
        const data = await PoiBrowserService.getCustomPoisList(meta, environment, filterByExpiration, avoidCache);
        const dataFormatted = data.map((customPOI) => {
            if (customPOI.modified) {
                return {
                    ...customPOI,
                    modified: new Date(customPOI.modified),
                };
            }
            return customPOI;
        });
        return dataFormatted;
    },
    async getCustomPoisDataset(meta, environment, id) {
        const user = getAuth0ServiceIntance().getUser();
        const data = await PoiBrowserService.getCustomPoisDataset(meta, environment, {
            id,
            user_email: user,
        });
        const regExp = new RegExp(SEPARATOR, 'g');
        const dataFormatted = data.map((customPOI) => {
            let addressFormatted = '';
            if (customPOI.address) {
                addressFormatted = customPOI.address.replace(regExp, ', ');
            }
            let geom = '';
            if (customPOI.geom) {
                const lat = customPOI.geom.coordinates[1].toFixed(5);
                const lng = customPOI.geom.coordinates[0].toFixed(5);
                geom = `${lat}, ${lng}`;
            }
            return {
                ...customPOI,
                geom,
                addressFormatted,
            };
        });
        return dataFormatted;
    },
    async getCustomPois(meta, environment, categories, countries, avoidCache = false) {
        let data = await PoiBrowserService.getCustomPois(meta, environment, countries, avoidCache);
        data = data.map((customPOI) => {
            if (customPOI.top_category) {
                customPOI.top_category += ' Cat.';
            }
            return customPOI;
        });
        data = addTopCategoryToCustomPOIs(data, categories);
        const dataFormatted = [];
        if (data.length === 0) {
            return dataFormatted;
        }
        let lastCategory = data[0].top_category;
        dataFormatted.push({
            name: data[0].top_category,
            id: data[0].top_category,
            parentId: '',
            level: 1,
            count: 0,
            children: [],
        });
        for (const category of data) {
            if (category.top_category !== lastCategory) {
                lastCategory = category.top_category;
                dataFormatted.push({
                    name: category.top_category,
                    id: category.top_category,
                    parentId: '',
                    level: 1,
                    count: category.occurrences,
                    children: [
                        {
                            name: category.name,
                            id: category.id,
                            parentId: category.top_category,
                            level: 2,
                            count: category.occurrences,
                            children: [],
                        },
                    ],
                });
            }
            else {
                dataFormatted[dataFormatted.length - 1].count += category.occurrences;
                dataFormatted[dataFormatted.length - 1].children.push({
                    name: category.name,
                    id: category.id,
                    parentId: category.top_category,
                    level: 2,
                    count: category.occurrences,
                    children: [],
                });
            }
        }
        // Reorder the array to put uncategorized at the end
        if (dataFormatted.length > 0 &&
            (dataFormatted[0].name === '' || dataFormatted[0].name === null)) {
            const uncategorized = dataFormatted.shift();
            // To avoid lint management related to undefined or null
            if (uncategorized === undefined) {
                throw new Error('Imposible Error and should not happen. Array has content, but shift returns undefined!!!!!');
            }
            uncategorized.name = 'uncategorized';
            dataFormatted.push(uncategorized);
        }
        return dataFormatted;
    },
    async getCategories(meta, environment, countries) {
        const data = await PoiBrowserService.getCategories(meta, environment, countries);
        if (data.length === 0) {
            return [];
        }
        const dataRenamedCategories = data.map((category) => {
            category.top_category += ' Cat.';
            return category;
        });
        const dataFormatted = [];
        let lastCategory = dataRenamedCategories[0].top_category;
        dataFormatted.push({
            name: dataRenamedCategories[0].top_category,
            id: dataRenamedCategories[0].top_category,
            parentId: '',
            level: 1,
            count: 0,
            children: [],
        });
        for (const category of dataRenamedCategories) {
            if (category.top_category !== lastCategory) {
                lastCategory = category.top_category;
                dataFormatted.push({
                    name: category.top_category,
                    id: category.top_category,
                    parentId: '',
                    level: 1,
                    count: category.occurrences,
                    children: [
                        {
                            name: category.sub_category,
                            id: category.sub_category,
                            parentId: category.top_category,
                            level: 2,
                            count: category.occurrences,
                            children: [],
                        },
                    ],
                });
            }
            else {
                dataFormatted[dataFormatted.length - 1].count += category.occurrences;
                dataFormatted[dataFormatted.length - 1].children.push({
                    name: category.sub_category,
                    id: category.sub_category,
                    parentId: category.top_category,
                    level: 2,
                    count: category.occurrences,
                    children: [],
                });
            }
        }
        return dataFormatted;
    },
    async getBrands(meta, environment, countries) {
        const data = await PoiBrowserService.getBrands(meta, environment, countries);
        if (data.length === 0) {
            return [];
        }
        const dataFormatted = data.map((brand) => {
            return {
                name: brand.brands,
                id: brand.brands,
                count: brand.occurrences,
                chain_id: brand.brands,
            };
        });
        if (dataFormatted[0].name === null) {
            dataFormatted.shift();
        }
        return dataFormatted;
    },
    async getTags(meta, environment, countries) {
        const data = await PoiBrowserService.getTags(meta, environment, countries);
        if (data.length === 0) {
            return [];
        }
        const tagsExpanded = data
            .map((tag) => {
            const subTags = tag.tags.split(',');
            return subTags.map((subTag) => {
                return {
                    tag: subTag,
                    occurrences: tag.occurrences,
                };
            });
        })
            .flat();
        const tagsReduced = tagsExpanded.reduce((acc, tag) => {
            const index = acc.findIndex((accTag) => accTag.tag === tag.tag);
            if (index === -1) {
                acc.push(tag);
            }
            else {
                acc[index].occurrences += tag.occurrences;
            }
            return acc;
        }, []);
        const tagsSorted = tagsReduced.sort((a, b) => {
            const a1 = a.tag.toLowerCase();
            const b1 = b.tag.toLowerCase();
            if (a1 < b1) {
                return -1;
            }
            if (a1 > b1) {
                return 1;
            }
            return 0;
        });
        const dataFormatted = tagsSorted.map((tag) => {
            return {
                name: tag.tag,
                id: tag.tag,
                count: tag.occurrences,
                tag_id: tag.tag,
            };
        });
        if (dataFormatted[0].name === null) {
            dataFormatted.shift();
        }
        return dataFormatted;
    },
    async getLocationsByPattern(pattern, meta, environment, countries) {
        const data = await PoiBrowserService.getLocationsByPattern(pattern, meta, environment, countries);
        if (data.length === 0) {
            return [];
        }
        const dataFormatted = data.map((location) => {
            return {
                name: location.name,
                id: location.normalized_name,
                count: location.occurrences,
                countryId: location.country_id,
            };
        });
        return dataFormatted;
    },
    async uploadCustomPOIsGroup(meta, environment, customPOIsGroup) {
        const response = await PoiBrowserService.uploadCustomPOIsGroup(meta, environment, customPOIsGroup);
        if (response.errors !== undefined) {
            // eslint-disable-next-line no-console
            console.error('Error uploading custom POIs group', response);
            throw new Error('Error uploading custom POIs group');
        }
    },
    async getActiveCustomPoisGroups(meta, environment, customPOIsGroupId) {
        const response = await PoiBrowserService.getActiveCustomPoisGroups(meta, environment, customPOIsGroupId);
        return response;
    },
    async uploadCustomPOIsAddressGroupJob(meta, environment, customPOIsAddressGroup) {
        const response = await PoiBrowserService.uploadCustomPOIsAddressGroupJob(meta, environment, customPOIsAddressGroup);
        return response;
    },
    async checkStatusCustomPoisAddressJob(meta, environment, jobId) {
        const cartoJob = await PoiBrowserService.checkStatusCustomPoisAddressJob(meta, environment, jobId);
        return cartoJob;
    },
    async updateCustomPOIsGroup(meta, environment, customPOIsGroup) {
        const response = await PoiBrowserService.updateCustomPOIsGroup(meta, environment, customPOIsGroup);
        if (response.errors !== undefined) {
            // eslint-disable-next-line no-console
            console.error('Error updating custom POIs group', response);
            throw new Error('Error updating custom POIs group');
        }
    },
    async deleteCustomPOIsGroup(meta, environment, customPOIsGroupId) {
        const response = await PoiBrowserService.deleteCustomPOIsGroup(meta, environment, customPOIsGroupId);
        if (response.errors !== undefined) {
            // eslint-disable-next-line no-console
            let message = 'Error deleting custom POIs group';
            console.error(message, response);
            if (response.message &&
                response.message.includes('would affect rows in the streaming buffer, which is not supported')) {
                message = 'Could not delete recently created dataset. Try again later.';
                throw new ErrorMessage('TITLE_ERR_REMOVE', 'MESSAGE_ERR_DELETE_CUSTOM_POI_1', message, response.errors[0]);
            }
            throw new ErrorMessage('TITLE_ERR_REMOVE', 'MESSAGE_GENERIC_MESSAGE', message, response.errors[0]);
        }
    },
    async reactivateCustomPOIsGroup(meta, environment, customPOIsGroupId) {
        const response = await PoiBrowserService.reactivateCustomPOIsGroup(meta, environment, customPOIsGroupId);
        if (response.errors !== undefined) {
            // eslint-disable-next-line no-console
            const message = 'Error reactivating custom POIs group';
            console.error(message, response);
            throw new ErrorMessage('TITLE_ERR_REMOVE', 'MESSAGE_GENERIC_MESSAGE', message, response.errors[0]);
        }
    },
    async fetchLayer(meta, environment, cutomPOIsGroupIds) {
        const source = PoiBrowserService.fetchCustomPOIsLayerSource(meta, environment, cutomPOIsGroupIds);
        const token = AuthManager.getInstance().getToken();
        const data = await fetchLayerData({
            type: MAP_TYPES.QUERY,
            source,
            credentials: getFetchLayerCredentials(meta.base_url, token),
            connection: getConnection(meta, environment),
            format: 'geojson',
        });
        return data;
    },
    async customPOIsGeocoded(meta, environment, customPOIsGroupId) {
        const [total, notGeocoded] = await Promise.all([
            PoiBrowserService.getCountCustomPOISGeocoded(meta, environment, customPOIsGroupId),
            PoiBrowserService.getCustomPOIsUnableToGeocode(meta, environment, customPOIsGroupId),
        ]);
        const geocoded = total - notGeocoded.length;
        const unableToGeocode = isArrayEmpty(notGeocoded) ? undefined : notGeocoded;
        return {
            total,
            geocoded,
            unableToGeocode,
        };
    },
};
