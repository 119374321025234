import { getParamsFromFilter, isAudiencesQuintileInFilter, } from '@/plan/filter/audiences-quintile.helper';
import AudiencesQuintileBrowserService from '@/services/audiences-quantile-browser.service';
import { propsForAudiencesQuintileLayer } from '@/layers/audiences-quintile/audiences-quintile-layer';
import layerService from '@/layers/layerService';
import { AUDIENCES_QUINTILE_TILESET_LAYER } from '@/layers/constant';
import { isNotEmptyArray } from './array.helper';
import { getFilledColor, getLineWidth, getLineColor, filterElements, } from '@/layers/audiences-quintile/audiences-quintile-layer.helper';
function getDataRelatedProps(zipCodesByQuintiles, selectedQuintiles) {
    return {
        zipCodesByQuintiles,
        selectedQuintiles,
        getFillColor: (e) => getFilledColor(e, zipCodesByQuintiles, selectedQuintiles),
        getLineWidth: (e) => getLineWidth(e, zipCodesByQuintiles),
        getLineColor: (e) => getLineColor(e, zipCodesByQuintiles, selectedQuintiles),
        getFilterValue: (e) => filterElements(e, zipCodesByQuintiles),
        updateTriggers: {
            getLineColor: [zipCodesByQuintiles, selectedQuintiles],
            getLineWidth: [zipCodesByQuintiles, selectedQuintiles],
            getFillColor: [zipCodesByQuintiles],
        },
    };
}
function createAudiencesQuintileLayer(zipCodesByQuintiles, selectedQuintiles) {
    layerService.addLayer({
        ...propsForAudiencesQuintileLayer,
        ...getDataRelatedProps(zipCodesByQuintiles, selectedQuintiles),
    }, true);
}
function updateAudiencesQuitileLayer(zipCodesByQuintiles, selectedQuintiles) {
    const layerProps = layerService.getLayer(AUDIENCES_QUINTILE_TILESET_LAYER);
    if (!layerProps) {
        throw new Error('🛑 Audiences-quintile layer is not found when trying to update');
    }
    layerService.updateLayer(AUDIENCES_QUINTILE_TILESET_LAYER, {
        ...getDataRelatedProps(zipCodesByQuintiles, selectedQuintiles),
    });
}
function addOrUpdateAudienceLayer(zipCodesByQuintiles, selectedQuintiles) {
    console.debug('Adding or updating audience quintile layer');
    const isLayerAlreadyAddedToMap = layerService.hasLayer(AUDIENCES_QUINTILE_TILESET_LAYER);
    if (!isLayerAlreadyAddedToMap) {
        console.debug('Creating audiences quintile layer');
        createAudiencesQuintileLayer(zipCodesByQuintiles, selectedQuintiles);
    }
    else {
        console.debug('Updating audiences quintile layer');
        updateAudiencesQuitileLayer(zipCodesByQuintiles, selectedQuintiles);
    }
}
export function removeAudiencesQuintileLayer() {
    const isLayerAlreadyAddedToMap = layerService.hasLayer(AUDIENCES_QUINTILE_TILESET_LAYER);
    if (isLayerAlreadyAddedToMap) {
        layerService.removeLayer(AUDIENCES_QUINTILE_TILESET_LAYER);
    }
}
export async function setupAudiencesQuintileLayer(meta, environment, filter) {
    if (!isAudiencesQuintileInFilter(filter)) {
        return;
    }
    const params = getParamsFromFilter(filter);
    const zipCodesByQuintiles = await AudiencesQuintileBrowserService.getZipCodesByQuintile(meta, environment, params);
    const selectedQuintiles = params.audiencesQuintile.quintiles;
    if (!isNotEmptyArray(selectedQuintiles)) {
        throw new Error('Quintiles selection is empty when trying to render Audiences-quintile layer');
    }
    addOrUpdateAudienceLayer(zipCodesByQuintiles, selectedQuintiles);
}
export function isAudienceQuintileLayerInstantianedInDeckGl(checkVisibility) {
    const layer = layerService.hasLayer(AUDIENCES_QUINTILE_TILESET_LAYER);
    if (checkVisibility === undefined || checkVisibility === false) {
        return layer;
    }
    const isVisible = layerService.isVisible(AUDIENCES_QUINTILE_TILESET_LAYER);
    return layer && isVisible;
}
export function getQuintileForAsset(zipCode) {
    const quintiles = layerService.getLayer(AUDIENCES_QUINTILE_TILESET_LAYER)
        ?.props?.zipCodesByQuintiles;
    if (!quintiles) {
        return undefined;
    }
    const quintile = quintiles.get(zipCode).quintile;
    return quintile;
}
