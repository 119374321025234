/**
 *  Plan state module file
 **/

import { PRODUCTS_TYPES } from '@/constants'
import { HEATMAP_BY_TYPES } from '@/plan/constants'
import { getAppMetadata, getAppFlavour } from '@/plan/metadata/metadata'
import {
  getAudienceFeatureVersion,
  isPackagagesFeatureEnabled,
} from '@/plan/metadata/metadata.helper'
import { Audience, Poi, Package } from '@workspaces/types'

// metadata information
const appMetadata = getAppMetadata()
const proximityDefaultParams = appMetadata.plan_filters.widget_proximity
const audienceDefaultParams = appMetadata.plan_filters.widget_audiences
const allCountries = appMetadata.countries
const baseMaps = appMetadata.app_config.basemaps
const defaultSelectedCountries = appMetadata.countries
  .filter((country) =>
    appMetadata.plan_filters.widget_countries.default_selection_iso3.includes(
      country.iso3,
    ),
  )
  .map((country) => country.id)

export const MODEL_SUBPLAN_FILTERS = () => ({
  tabname: '',
  countries: defaultSelectedCountries,
  geoboundaries: {
    region_1: [],
    region_2: [],
    region_3: [],
    region_4: [],
    region_5: [],
    region_6: [],
    region_7: [],
    region_8: [],
    region_9: [],
    region_10: [],
    region_11: [],
    region_12: [],
    region_15: [],
    region_16: [],
    region_17: [],
    region_18: [],
  },
  types: {
    [PRODUCTS_TYPES.L1_PANEL_CLASS]: [],
    [PRODUCTS_TYPES.L2_PRODUCT]: [],
    [PRODUCTS_TYPES.L3_SUBPRODUCT]: [],
    [PRODUCTS_TYPES.L4]: [],
    [PRODUCTS_TYPES.L5]: [],
    [PRODUCTS_TYPES.L6]: [],
    [PRODUCTS_TYPES.L7]: [],
    [PRODUCTS_TYPES.L8]: [],
    [PRODUCTS_TYPES.L9]: [],
  },
  [PRODUCTS_TYPES.IS_DIGITAL]: undefined,
  proximity: {
    distance: proximityDefaultParams.types.radius.selected_max_distance,
    min_distance: proximityDefaultParams.types.radius.selected_min_distance,
    max_distance: proximityDefaultParams.types.radius.selected_max_distance,
    max_num_panels: 0,
    method: proximityDefaultParams.types.radius.name,
    operation: Poi.POIsFilterOperation.Union,
    data: [],
    categoires: [],
    brands: [],
    tags: [],
  },
  assets_excluded: [],
  assets_included: [],
  panels: { assetsIds: [] },
  panelsExcluded: { assetsIds: [] },
  audiences: [],
  audiencesGroups: [],
  audiencesType: audienceDefaultParams.by_index.enable
    ? audienceDefaultParams.by_index.name
    : audienceDefaultParams.by_percentage.name,
  // TODO if v2 arrey else number
  audiencesIndex:
    getAudienceFeatureVersion() === Audience.AudienceFeatureVersion.V1
      ? audienceDefaultParams.by_index.default_max_value
      : [
          audienceDefaultParams.by_index.default_min_value,
          audienceDefaultParams.by_index.default_max_value,
        ],
  audiencesPercent:
    getAudienceFeatureVersion() === Audience.AudienceFeatureVersion.V1
      ? audienceDefaultParams.by_percentage.selected_max_value
      : [
          audienceDefaultParams.by_percentage.selected_min_value,
          audienceDefaultParams.by_percentage.selected_max_value,
        ],
  userAudiencesGroup: [],
  delivery: null,
  polygon_geom: null,
  geographical_distribution: {
    optimizeBy: 'impressions',
    distanceFilterActive: false,
    distanceFilter: 3,
    maxFilterActive: false,
    maxFilters: {
      region_1: {
        active: false,
        max: 10,
      },
      region_2: {
        active: false,
        max: 10,
      },
      region_3: {
        active: false,
        max: 10,
      },
      region_4: {
        active: false,
        max: 10,
      },
      region_5: {
        active: false,
        max: 10,
      },
      region_6: {
        active: false,
        max: 10,
      },
      region_7: {
        active: false,
        max: 10,
      },
      region_8: {
        active: false,
        max: 10,
      },
      region_9: {
        active: false,
        max: 10,
      },
      region_10: {
        active: false,
        max: 10,
      },
      region_11: {
        active: false,
        max: 10,
      },
      region_12: {
        active: false,
        max: 10,
      },
    },
    regionExceptions: {},
  },
  package: null,
  audiencesQuintile: {
    quintiles: [1, 2, 3],
    audiences: [],
  },
})

export const MODEL_PLAN_PACKAGE_PRICE = (
  price,
  production,
  assetAllocation,
  materialHandling,
) => ({
  grossPrice: price,
  discounts: {
    freePercentage: 0,
    mediaAgencyPercentage: 0,
    otherPercentage: 0,
  },
  fees: {
    production,
    assetAllocation,
    materialHandling,
    photography: 0,
  },
  totalNet: price + production + assetAllocation + materialHandling,
})

export const MODEL_PLAN_PACKAGE = () => {
  if (isPackagagesFeatureEnabled(appMetadata)) {
    return {
      state: Package.State.Draft,
      packages: undefined,
      // id: undefined,
      // name: undefined,
      price: null,
      assets: undefined,
      impressions: undefined,
      availability: undefined,
      filter: {
        priceRange: appMetadata.plan_filters.widget_packages.price_range,
        dateRange: [],
        panelType: {
          digital: undefined,
          l1: [],
          l2: [],
          l3: [],
          l4: [],
        },
      },
      bookedConfirmByAdminAt: undefined,
      bookedFinalizedAt: undefined,
      actions: [],
    }
  }

  return null
}

export const MODEL_PLAN = () => ({
  loading: false,
  dirty: false,
  aida_id: null,
  filters: [MODEL_SUBPLAN_FILTERS()],
  proximity: {
    categories: [],
    brands: [],
    locations: [],
    tags: [],
  },
  created_by: null,
  count: 0,
  impressions: 0,
  legend: {
    productClass: 'l2product_id',
    hiddenProductsId: [],
    excludeAssets: false,
    canAssetsExcludedLayerBeShown: false,
    facingOrientationIcons: false,
    angleNorthMap: false,
    poisBufferVisibility: true,
    poisVisibility: true,
    boundariesVisibility: true,
    assetsIdsLayerVisibility: false,
    colorizeAssets: false,
    audiencesQuintile: true,
    heatmapAssetsVisibility: false,
    heatmapAssetsType: HEATMAP_BY_TYPES.IMPRESSIONS,
  },
  package: MODEL_PLAN_PACKAGE(),
  agency: null,
  custom_geoboundaries: [],
  custom_pois: [],
  brand: null,
  client: null,
  basemap: baseMaps.options.find((basemap) => baseMaps.default === basemap.id)
    .id,
})

export const MODEL_ASSETS = () => ({
  results: [],
  meta: {},
  count: 0,
  page: 1,
  page_size: 10,
  loading: false,
})

export const MODEL_NEW_POI_DATASET_FORM = () => ({
  id: null,
  type: appMetadata.features.manage_pois.default_type,
  name: '',
  description: '',
  shared: true,
  categories: [],
})

export const initialViewState = () => ({
  longitude: 40.514933,
  latitude: -91.139458,
  zoom: 4,
  bearing: 0,
  pitch: 0,
})

export const state = {
  flavour: getAppFlavour(),
  metadata: appMetadata,
  plan: MODEL_PLAN(),
  activeSubPlanFiltersIndex: 0,
  plan_backup: MODEL_PLAN(),
  assets: MODEL_ASSETS(),
  assetsExcluded: MODEL_ASSETS(),
  assetsProperties: {
    allDigital: false,
    allStatic: false,
  },
  mapUpdateFlags: {
    assets: false,
    pois: false,
    geoboundaries: false,
  },
  countries: allCountries,
  viewState: initialViewState(),
  proximityFlags: {
    open: {},
    selected: {},
    halfSelected: {},
    hidden: {},
  },
  packagesFlags: {
    open: {
      packages: true,
    },
    selected: {},
    halfSelected: {},
    hidden: {},
  },
  openCustomGeometry: null,
  newPoiDatasetForm: MODEL_NEW_POI_DATASET_FORM(),
  fixme: null,
  latestdelivery: null,
  assetsFinishedLoad: false,
  geographicalDistributionRegions: [],
  loadingGeographicalDistributionRegions: false,
  assetsDetails: null,
  defaultUserCountries: [2],
  assetsReadyWithAllProperties: false,
  highlightedAssets: null,
  forceLogout: false,
  packages: [],
  poisColors: {},
  transitionBetweenBaseMaps: false,
  geoBoundariesGeometriesCache: [],
  plans: {
    subscribed: false,
    user: [],
    public: [],
    comments: {
      latest_plans_updated: [],
      subscribed: false,
      data: [],
      updatedAt: null,
    },
  },
}
