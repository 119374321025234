import BrowserEnvironmentResolver from './environment.helper';
import PlanService from '@/services/plan.service';
import { commit, getters } from '@/utils/vuex';
import { MODULE_NAME_PLAN } from '@/store/plan';
import { GETTERS, MUTATIONS } from '@/store/plan/constants';
import { getRealtimeReactionForPlans, isCommentsFunctionalityEnabled, isPlansRealtimeFeatureEnabled, } from '@/plan/metadata/metadata.helper';
import { subscribeToPlans as subscribeToPlansService } from '@workspaces/services';
import { getAppMetadata } from '@/plan/metadata/metadata';
import { getInstance as getAuth0ServiceInstance } from '@/auth/index';
import { updateQuickAcessPlansComments } from './comment.helper';
async function fetchMyPlans(userId, meta, environment) {
    try {
        const plans = await PlanService.getPlansByUserId(meta, environment, userId);
        const plansFormatted = plans.map((p) => {
            return {
                ...p,
                modified: p.modified_at?.toISOString(),
                user: {
                    id: p.created_by,
                    name: p.created_by.split('@')[0],
                },
            };
        });
        commit(MODULE_NAME_PLAN, MUTATIONS.SET_MY_PLANS, plansFormatted);
        console.debug('📒 My plans: ', plansFormatted);
    }
    catch (error) {
        commit(MODULE_NAME_PLAN, MUTATIONS.SET_MY_PLANS, []);
    }
}
async function fetchPublicPlans(meta, environment) {
    try {
        const plans = await PlanService.getPublicPlans(meta, environment);
        const plansFormatted = plans.map((p) => {
            return {
                ...p,
                modified: p.modified_at?.toISOString(),
                user: {
                    id: p.created_by,
                    name: p.created_by.split('@')[0],
                },
            };
        });
        commit(MODULE_NAME_PLAN, MUTATIONS.SET_PUBLIC_PLANS, plansFormatted);
        console.debug('📒 Public plans: ', plansFormatted);
    }
    catch (error) {
        commit(MODULE_NAME_PLAN, MUTATIONS.SET_PUBLIC_PLANS, []);
    }
}
async function fetchPlans(userId, meta, environment) {
    await Promise.all([
        fetchMyPlans(userId, meta, environment),
        fetchPublicPlans(meta, environment),
    ]);
}
export async function fetchPlansVisibleByUser() {
    console.debug('📒 Fetching plans visible by user');
    const userId = getAuth0ServiceInstance().getUser();
    const meta = getAppMetadata();
    const environment = BrowserEnvironmentResolver.getInstance();
    await fetchPlans(userId, meta, environment);
    // If comments related to plans is active, we also have to update comments
    if (isCommentsFunctionalityEnabled(meta)) {
        console.debug('📒 Comments are enabled, updating comments for visible plans by user');
        await updateQuickAcessPlansComments();
    }
}
export function getFetchedPlansForComments() {
    const userPlans = getters(MODULE_NAME_PLAN, GETTERS.GET_MY_PLANS);
    const publicPlans = getters(MODULE_NAME_PLAN, GETTERS.GET_PUBLIC_PLANS);
    const uniquePlans = [];
    const plans = [...userPlans, ...publicPlans];
    plans.forEach((plan) => {
        const planForCommentFetching = {
            planId: plan.id,
            planName: plan.name,
            createdBy: plan.created_by,
        };
        if (!uniquePlans.some((p) => p.planId === plan.id)) {
            uniquePlans.push(planForCommentFetching);
        }
    });
    return uniquePlans;
}
export async function subscribeToPlans(meta, environment) {
    if (!isPlansRealtimeFeatureEnabled(meta)) {
        return;
    }
    const isSubscribed = getters(MODULE_NAME_PLAN, GETTERS.GET_IS_SUBSCRIBED_TO_PLANS);
    if (isSubscribed) {
        console.warn('Already subscribed to plans. This should be not happening, please check how this is being called');
        console.debug('📒 ✋ Already subscribed to plans. Nothing to do');
        return;
    }
    console.debug('📒📒📒📒 Subscribing to plans 📒📒📒📒');
    const debounceDelay = getRealtimeReactionForPlans(meta);
    console.debug('📒 Debounce delay for plans in seconds: ', debounceDelay);
    const unscribeFunction = await subscribeToPlansService(meta, environment, debounceDelay, fetchPlansVisibleByUser);
    window.addEventListener('beforeunload', () => {
        if (unscribeFunction) {
            console.debug('📒 ✅ Unsubscribing from plans before closing');
            unscribeFunction();
            commit(MODULE_NAME_PLAN, MUTATIONS.SET_IS_SUBSCRIBED_TO_PLANS, false);
        }
    });
    commit(MODULE_NAME_PLAN, MUTATIONS.SET_IS_SUBSCRIBED_TO_PLANS, true);
}
