/* eslint-disable no-console */
import Vue from 'vue'
import createAuth0Client from '@auth0/auth0-spa-js'
import { setDefaultCredentials } from '@deck.gl/carto/typed'
import * as jwtDecode from 'jwt-decode'
import { getAppMetadata } from '@/plan/metadata/metadata'
import authService, { NO_TOKEN_FOUND } from '@/api/authService'
import AuditService from '@/services/audit/audit.service'
import { Audit, Auth } from '@workspaces/types'
import { getCarto3ApiTokenFromLocalStorage } from '@/helpers/testing/testing.helper'
import BrowserEnvironmentResolver from '@/helpers/environment.helper'
import { AuthManager } from '@workspaces/services'
import BrowserCartoAuthorizer from '@/auth/carto-auth'
import { initializeUserPermissionManager } from '@/helpers/auth-browser.helper'
import CartoMetricEvents from '@/services/carto-metric-event.service'
import { subscribeToComments } from '@/helpers/comment.helper.ts'
import { subscribeToPlans } from '@/helpers/plans.helper.ts'

let instance
const maxLoginRetries = 3
let loginRetries = 0

/** Returns the current instance of the SDK */
export const getInstance = () => instance

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  redirectUri = `${window.location.origin}/login`,
  ...options
}) => {
  if (instance) return instance

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        loading: false,
        isUserAuthenticated: false,
        user: {},
        auth0Client: null,
        error: null,
        accessTokenCartoV3: 'Waitting for token to be set',
        userCountries: [],
        role: 'unset',
        cartoUser: 'unset',
        accountId: 'unset',
      }
    },
    methods: {
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback(url) {
        if (loginRetries >= maxLoginRetries) {
          console.error(
            'Max login retries reached, loggin out and cleanning all application data',
          )
          this.auth0Client.logout()
          authService.removeAllApplicationData()
        }
        loginRetries++

        this.loading = true
        try {
          // If the user is returning to the app after   authentication..
          // handle the redirect and retrieve tokens
          await this.auth0Client.handleRedirectCallback(url)
          this.error = null
        } catch (e) {
          this.error = e
        } finally {
          // Initialize our internal authentication state
          this.isUserAuthenticated = await this.auth0Client.isAuthenticated()
          const token = await this.auth0Client.getTokenSilently()
          console.debug('🟧 Auth0: User token', { token })
          if (authService.isWrongToken(token)) {
            authService.setFirstLogin(true)
            console.debug(
              '🟧 Auth0: User does not has organization, assigning organization...',
              { token },
            )
            await authService.joinInviteUserToOrganization(token)
            console.debug('🟧 Auth0: Logging out and redirecting to login...')
            this.auth0Client.logout()

            authService.removeAllApplicationData()
            this.auth0Client.loginWithRedirect()
            // eslint-disable-next-line no-unsafe-finally
            return
          }
          // Audit event to register login
          this.setAccessTokenCartoV3(token)
          const isFirstLogin = authService.isFirstLogin()
          const authManager = AuthManager.getInstance()
          authManager.init(new BrowserCartoAuthorizer())
          const type = isFirstLogin
            ? Audit.AuditEventType.LoginFirstTime
            : Audit.AuditEventType.Login
          AuditService.createEvent(
            getAppMetadata(),
            BrowserEnvironmentResolver.getInstance(),
            { type },
          )
          authService.setFirstLogin(false)
          CartoMetricEvents.sendLoginEvent(
            getAppMetadata(),
            BrowserEnvironmentResolver.getInstance(),
          )
          await initializeUserPermissionManager()

          subscribeToPlans(
            getAppMetadata(),
            BrowserEnvironmentResolver.getInstance(),
          )

          subscribeToComments(
            this.getUser(),
            getAppMetadata(),
            BrowserEnvironmentResolver.getInstance(),
          )

          this.loading = false
        }
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect() {
        return this.auth0Client?.loginWithRedirect()
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o) {
        return this.auth0Client?.getIdTokenClaims(o)
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently(o) {
        return this.auth0Client?.getTokenSilently(o)
      },
      /** Logs the user out and removes their session on the authorization server */
      logout() {
        return this.auth0Client?.logout()
      },
      setAccessTokenCartoV3(token) {
        this.accessTokenCartoV3 = token
        if (token !== NO_TOKEN_FOUND) {
          const tokenInfo = jwtDecode(token)
          this.user = tokenInfo[getAppMetadata().auth0_user_key]
          this.cartoUser = tokenInfo.sub
          this.accountId = tokenInfo[getAppMetadata().auth0_account_id_key]
          // Para la layer de los POIs
          // TODO: obtener los datos del metadata
          const testingToken = getCarto3ApiTokenFromLocalStorage(
            BrowserEnvironmentResolver.getInstance(),
          )
          setDefaultCredentials({
            apiVersion: 'v3',
            apiBaseUrl: getAppMetadata().base_url,
            accessToken: testingToken ?? token,
          })
        }
      },
      getCartoUser() {
        return this.cartoUser
      },
      getAccountId() {
        return this.accountId
      },
      getAccessTokenCartoV3() {
        // console.log('Asking for carto3 token')

        // TODO: Playwrigth - Find a better way of doing this
        const testingToken = getCarto3ApiTokenFromLocalStorage(
          BrowserEnvironmentResolver.getInstance(),
        )
        if (testingToken) {
          console.log('👿 Testing time, returning testing token', testingToken)
          return testingToken
        }

        const token = this.accessTokenCartoV3
        // console.log('Carto3 token:', token)
        if (
          token === undefined ||
          token === NO_TOKEN_FOUND ||
          token === 'Waitting for token to be set'
        ) {
          console.log('No carto3 token found, redirecting to login')
          /* try {
            const auth0Token = useAuth0?.getAuthCarto3()
            if (auth0Token === undefined || auth0Token === NO_TOKEN_FOUND) {
              window.location.href = `${window.location.origin}/login`
            }
          } catch (e) {
            console.error(e)
            window.location.href = `${window.location.origin}/login`
          } */
          // this.loginWithRedirect()
          window.location.href = `${window.location.origin}/login`
        } else {
          // console.log('Carto 3 token found. Token:', token)
          return token
        }
      },
      setRole(role) {
        this.role = role
      },
      isAdmin() {
        return this.role === Auth.Role.Admin
      },
      isPowerUser() {
        return this.role === Auth.Role.PowerUser
      },
      getUser() {
        return this.user
      },
      getRole() {
        return this.role
      },
      setUserCountries(countries) {
        this.userCountries = countries
      },
      getUserCountries() {
        return this.userCountries
      },
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      // Create a new instance of the SDK client using members of the given options object
      this.auth0Client = await createAuth0Client({
        ...options,
        client_id: options.clientId,
        redirect_uri: redirectUri,
      })
      console.debug('🟧 Auth0: Created client', this.auth0Client)
    },
  })

  return instance
}

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options)
  },
}
